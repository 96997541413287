<template>
    <!-- brand -->
  <brand-discount
    :shop-data="brandData"
    img-height="6.25rem"
    img-radius=".16rem"
  />
</template>

<script>
import BrandDiscount from '@/components/BrandDiscount.vue'
export default {
  name: '',
  components: {
    'brand-discount': BrandDiscount
  },
  props: {
  },
  data () {
    return {
      brandData: [
        {
          title: 'Tanishq:Luxurious and gorgeous!',
          imageUrl: '/images/index_home/fake/Tanishq.png',
          content: 'Tanishq is one of the largest jewelry retail chains in the country. Tanishq jewelry is very popular due to its outstanding design.'
        },
        {
          title: 'Joyalukkas：Famous Indian jewelry brand.',
          imageUrl: '/images/index_home/fake/Joyalukkas.png',
          content: 'Joy Alukkas was founded in Thrissur, Kerala in 1956, occupying the southern market and owning 140 stores in 11 countries. Their works include traditional South Indian gold jewelry, exquisite gold and gem jewelry for all occasions.'
        },
        {
          title: 'PC Jeweler:Meaningful jewelry!',
          imageUrl: '/images/index_home/fake/PCJeweler.png',
          content: 'Founded in 1938 and headquartered in Kolkata, it has 91 stores in major cities in India. Their gold and jewelry designs are artistic, and their price ranges are suitable for every social class.'
        },
        {
          title: 'ORRA:Enjoy yourself through jewelry.',
          imageUrl: '/images/index_home/fake/ORRA.png',
          content: "As one of the most trusted diamond jewelry brands in India, Orra needs no introduction. They have 34 stores in 25 cities, and their elegant, simple atmosphere attracts today's fashionable modern women"
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
