<template>
  <div>
    <branch-logout />
  </div>
</template>

<script>
import BranchLogout from './components/BranchLogout.vue'
export default {
  name: '',
  components: {
    'branch-logout': BranchLogout
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
</style>
